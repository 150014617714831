import http from '@/configs/http';
import { ItemOrder, TListOrder } from '@/types/Coupon';
import { render } from '@testing-library/react';
import { Pagination, PaginationProps, Table } from 'antd';
import { ref } from 'firebase/storage';
import { title } from 'process';
import React, { useEffect, useState } from 'react';
import { items } from '../../../constant';

const columns = [
    {
        title: 'Mã đơn hàng giới thiệu',
        dataIndex: 'haravanOrderId',
        key: 'haravanOrderId',
        // render: (data: ItemOrder) => <p>{data.haravanOrderId}</p>,
    },
    {
        title: 'Mã giới thiệu',
        dataIndex: 'couponRef',
        key: 'couponRef',
        render: (data: { couponHaravanCode: string }) => <p>{data.couponHaravanCode}</p>,
    },
];

export const TableReferals = React.memo(({ userId }: { userId: string }) => {
    const [page, setPage] = useState(1);
    const [listOrder, setListOrder] = useState<TListOrder>();

    const handleGetListOrder = async () => {
        try {
            const response = await http.get(`order?userId=${userId}&page=${page}&limit=10`);

            setListOrder(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!userId) return;
        handleGetListOrder();
    }, [userId, page]);

    const onChange: PaginationProps['onChange'] = (page) => {
        setPage(page);
    };
    return (
        <>
            <Table className="" pagination={false} columns={columns} dataSource={listOrder?.items ?? []} />
            {Number(listOrder?.meta.totalPages) > 1 && (
                <Pagination total={Number(listOrder?.meta.totalPages) * 10} current={page} onChange={onChange} />
            )}
        </>
    );
});
